/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { useIntl } from 'gatsby-plugin-intl';
import { Form } from '@components/Form';
import { Box, Heading } from '@primitives';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TextSection } from '@components/TextSection';
import { isEmail } from '../components/Form/formValidators';
import { useStores } from '../stores/index';
import { ICurrentUserStore } from '../types/CurrentUser';

interface IChangeEmailPage {
}

const Content = styled(Box)`
  max-width: 800px;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
`;

const Header = styled(Box)`
  text-align: center;
`;

const ChangeEmailPage: FC<IChangeEmailPage> = observer(() => {
  const { formatMessage } = useIntl();
  const {
    currentUserStore: { changeEmail },
  }: {
    currentUserStore: ICurrentUserStore,
  } = useStores();

  const onSubmit = (values: any, cb: Function) => {
    changeEmail(values, cb)
  };

  const formFields = [{
    id: 'email',
    validators: [isEmail],
    placeholder: "anna.asukas@esimerkki.com",
    required: true,
  }, {
    id: 'password',
    label: "currentPassowrd",
    placeholder: '*****',
    type: 'password',
    required: true,
  }];

  return (
    <ContentPageLayout>
      <Container>
        {(
          <Content>
            <TextSection
              title={formatMessage({ id: 'pages.changeEmail.title' })}
              text={formatMessage({ id: 'pages.changeEmail.instructions' })}
            />
            <Header mb="2">
              <Heading level="3">
              </Heading>
            </Header>
            <Box mt="2">
              <Form
                id="change-email"
                values={{}}
                fields={formFields}
                styles={['singleColumn', 'labelOnTop', 'centerLabels']}
                submitText="pages.changeEmail.confirmNewEmail"
                onSubmit={onSubmit}
              />
            </Box>
          </Content>
        )}
      </Container>
    </ContentPageLayout >
  )
});


export default ChangeEmailPage;
